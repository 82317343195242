<template>
    <div class="wallet animate__animated animate__fadeInDown">
        <div class="box balance" v-if="userinfo">
            <div class="flex">
                <span>{{$t('wallet.amountLabel')}}</span>
                <a-icon @click="goWithdrawls" type="file-text" />
            </div>
            <div class="amount color-primary">
                {{userinfo.balance/1000000}} {{$t('common.currency')}}
            </div>
            <div class="withdrawl flex">
                <div class="btn btn-primary-ghost" @click="withdrawl">
                    {{$t('wallet.withdrawl.btn')}}
                </div>
                <div class="btn btn-primary" @click="recharge">
                    {{$t('recharge.recharge')}}
                </div>
            </div>

        <div v-for="l,i in list" :key="'log-'+i" class=" flex log-item">
            <div class="flex title">
                <div class="label">{{$t('wallet.typeText')[l.type]}}</div>
                <div class="time color-gray">
                    {{l.created_at}}
                </div>
            </div>
            <div class="amount">
                {{(l.amount>0?'+':'') + l.amount/1000000}}{{$t('common.currency')}}
            </div>
            <div class="color-gray info">
                <span v-if="l.type==0">{{$t('wallet.labels.SerialNumber')}}:#{{l.comment}}</span>
                <span v-if="l.type==1">{{l.comment}}</span>
                <span v-if="l.type==2" @click="$router.push({name:'task',params:{id:l.comment}})">{{$t('wallet.labels.orderId')}}:#{{l.comment}}</span>
                <span v-if="l.type==3">{{$t('wallet.labels.orderId')}}:#{{l.comment}}</span>
                <span v-if="l.type==4">{{l.comment}}</span>
            </div>
        </div>
        <div v-if="showLoadingMore"
            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
            <a-spin v-if="loading" />
            <a-button v-else type="dashed" ghost @click="getLogs()">
                {{$t('common.more')}}
            </a-button>
        </div>
        </div>

    </div>
</template>
<script>
export default {
    name: "Wallet",
    data() {
        return {
            userinfo: {},
            noFundPass: false,
            loading: false,
            showLoadingMore:true,
            page:1,
            pageSize:10,
            asc:0,
            list:[],
        };
    },
    components: {},
    mounted() {
        this.getUserinfo();
        this.getLogs();
    },
    methods: {
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        getLogs(append = true){
            this.loading = true;
            this.$http.call(this.$http.api.ulogs,{
                data:{page:this.page,size:this.pageSize,sort:this.asc}
            }).then(res=>{
                console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.page++;
                    if(append){
                        this.list = this.list.concat(resp.data);
                    }else{
                        this.list = resp.data;
                    }
                    if(resp.data.length<this.pageSize){
                        this.showLoadingMore = false;
                    }
                }else{
                    this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        withdrawl(){
            if(!this.userinfo.fund_password){
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowSetFundpassBox',true);
            }else{
                this.$store.commit('setBtmBox',true);
                this.$store.commit('setShowWithdrawlBox',true);
            }
        },
        recharge(){
            this.$store.commit('setBtmBox',true);
            this.$store.commit('setShowRechargeBox',true);
        },
        goWithdrawls(){
            this.$router.push({name:'withdrawls'});
        },
        
    },
};
</script>
<style lang="less" scoped>
.wallet {
    width:100%;
    padding: 0 15px;
    .balance{
        padding: 15px;
        text-align: left;
        .amount{
            font-size: 20px;
            font-weight: bold;
            padding: 7px 0;
        }
        .withdrawl{
            padding: 0;
            margin: 16px 0;
            .btn{
                padding: 8px 12px;
            }
        }
    
        .log-item{
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin: 10px -15px;
            padding: 0 15px;
            border-top: 1px solid @gray;
            .title{
                width: 100%;
                justify-content: space-between;
                .label{
                    color: @primary-color;
                    font-weight: bold;
                }
            }
            .amount{
                font-size: 15px;
            }
        }
    }
}
</style>
